/* You can add global styles to this file, and also import other style files */

@import "./assets/scss/modules/_fonts.scss";

@import "./assets/scss/modules/_variables.scss";

html {
    font-size: 14px;
    @media only screen and (min-width: 960px) {
        font-size: 16px;
    }
}
html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-snack-bar-container {
    background-color: $elm_lightgray;
    color: black;
    border-radius: 0;
    border: none;
    box-shadow: none;

    padding: 0!important;

    //col-12
    -ms-flex: 0 0 100%!important;
    flex: 0 0 100%!important;
    max-width: 100%!important;

    //col-sm-6
    @media (min-width: 576px) {
        -ms-flex: 0 0 50%!important;
        flex: 0 0 50%!important;
        max-width: 50%!important;
    }

    @media (max-width: 575px) {
        margin:0!important;
    }
    
    //col-lg-4
    @media (min-width: 992px) {
        -ms-flex: 0 0 33.333333%!important;
        flex: 0 0 33.333333%!important;
        max-width: 33.333333%!important;
    }

    //col-xl-3
    @media (min-width: 1200px) {
        -ms-flex: 0 0 25%!important;
        flex: 0 0 25%!important;
        max-width: 25%!important;
    }
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

.top-footer {
  background: rgb(255,255,255);
  background: linear-gradient(180deg, #F9FBFC 0%, #E3EAF3 96%);
  height: 5px;
}

.footer-elm {
  padding: 20px;

  a {
    color: #ACACAC !important;
  }
}

.footer-elm ul {
  font: 13px BoschSansLight;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
}

.footer-elm span {
  font: 13px BoschSansBlack;
}

.copyright {
  position: absolute;
  bottom: 0;
  z-index: 1030;
  right: 10px;
  color: #ACACAC !important;
  font: 13px BoschSansLight;
  text-decoration: none;

  @media (min-width: 768px) {
    display: normal !important;
  }

  @media (max-width: 767px) {
    display: none !important;
  }
}

.copyrightXS {
  bottom: 0;
  z-index: 1030;
  right: 10px;
  color: #ACACAC !important;
  font: 13px BoschSansLight;
  text-decoration: none;

  @media (min-width: 768px) {
    display: none !important;
  }

  @media (max-width: 767px) {
    display: normal !important;
  }
}

footer {
  font-style: normal !important;
  font-family: 'BoschSansBlack', sans-serif;
  font-size: 13px;
  background-color: white;
  color: #838383;
}
