/* GLOBAL */
$default_blue: #005691;
$default_hover: lightblue;
$elm_blue: #0047BA;
$elm_darkgray: #888888;
$default_magenta: #E03554;
$form_background: #F1F6FD;
$success-green: #2CE638;
$warning-yellow:#ffc107;
$elm_lightgray: #F2F2F2;
